<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({
    mobile: false,
    mediaQuery: window.matchMedia('screen and (max-height: 920px) '),
    closeItemPopup: false,
  }),
  computed: {
    ...mapGetters(['getQuizHubStage', 'getQuizData', 'getSound', 'getBgMusicTime'])
  },
  beforeDestroy() {
    document.body.style.overflow = "visible"

  },
  beforeMount() {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);


    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      this.mobile = true
    }
    if (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) {
      this.mobile = true
    }

    if (isAndroid || iOS === true) {
      this.mobile = true
    }
  },
  mounted() {
    document.title = "Нестандартна медицина в масовій культурі";
    document.body.style.overflow = "hidden"

    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh2', `${vh}px`);


    window.addEventListener("resize", () => {

      this.handleDeviceChange(this.mediaQuery)

      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh2', `${vh}px`);

    });

    this.handleDeviceChange(this.mediaQuery)

    let audio = document.getElementById('bg-audio')
    audio.currentTime = this.getBgMusicTime

    if (!this.getSound) {
      document.getElementById('bg-audio').pause()
    }
  },
  methods: {
    ...mapActions(['doQuizHubStage', 'doSound', 'doBgMusicTime']),
    handleDeviceChange(e) {
      if (e.matches) {
        if (!this.mobile) {

          let hostElement = document.querySelector('#element');
          hostElement.style.height = 'auto !important'
          hostElement.style.width = '100% !important'

          let style = document.createElement('style')
          style.innerHTML = '#animation svg { width: 100% !important; height: auto!important; }' +
            '#animation {height: auto!important;justify-content: center;}'
          hostElement.shadowRoot.appendChild(style)
        }
      } else {
        if (!this.mobile) {
          let hostElement = document.querySelector('#element');

          hostElement.style.height = '100% !important'
          hostElement.style.width = 'auto !important'

          let style = document.createElement('style')
          style.innerHTML = '#animation svg { width: auto !important; height: 100%!important;}' +
            '#animation {height: 100% !important;justify-content: center;}'
          hostElement.shadowRoot.appendChild(style)
        }
      }
    },
    mute() {
      let audio = document.getElementById('bg-audio');
      if (!this.getSound) {
        audio.play()
        this.doSound(true)
      } else {
        audio.pause()
        this.doSound(false)
      }
    },
    openGame(id, disable) {
      if (!disable) {
        if (this.getSound) {
          document.getElementById('click-audio').play()
        }
        setTimeout(() => {
          this.$router.push({name: "quizGame", params: {id: id}})
          this.doBgMusicTime(document.getElementById('bg-audio').currentTime)
        }, 150)
      } else {
        this.closeItemPopup = !this.closeItemPopup
      }
    }
  }
}
</script>

<template>
  <section class="hub">
    <audio id="bg-audio" src="@/assets/audio/bg-music.mp3" loop autoplay preload></audio>
    <audio id="click-audio" src="@/assets/audio/btn-click.mp3"></audio>

    <div class="content stage3">
      <div class="sound-btn" @click="mute()">
        <p class="sound-btn__text">звук</p>
        <div class="sound-btn__icon">
          <svg v-if="getSound" width="16" height="16" viewBox="0 0 16 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.4091 1.38477H10.2771L7.11082 4.14247C6.7467 4.4596 6.28014 4.6343 5.79727 4.6343H4.01563C2.91106 4.6343 2.01562 5.52973 2.01562 6.6343V9.36547C2.01562 10.47 2.91106 11.3655 4.01563 11.3655H5.79727C6.28014 11.3655 6.7467 11.5402 7.11082 11.8573L10.2771 14.615H12.4091V1.38477Z"
              fill="black"/>
          </svg>

          <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9832 3.625L2.99316 14.615" stroke="black" stroke-width="1.4" stroke-linecap="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.4091 3.078V1.38477H10.2771L7.11082 4.14247C6.7467 4.4596 6.28014 4.6343 5.79727 4.6343H4.01563C2.91106 4.6343 2.01562 5.52973 2.01562 6.6343V9.36547C2.01562 10.47 2.91106 11.3655 4.01563 11.3655H4.12165L12.4091 3.078ZM12.4091 7.32064L7.51791 12.2118L10.2771 14.615H12.4091V7.32064Z"
                  fill="black"/>
          </svg>

        </div>
      </div>

      <div class="img">

        <div class="animation2">
          <lottie-player v-if="!mobile" id="element" src="/animation/bg-animation-full.json" background="transparent"
                         speed="1"
                         style="width: auto; height: 100%; display: flex;justify-content: center" autoplay loop>
          </lottie-player>
          <img v-else class="bg-mobile" src="@/assets/img/quiz-game/bg-img-tm.jpg" alt="">

          <div class="gradient"></div>

        </div>
      </div>

      <div class="games">
        <div class="games__content">

          <div class="corner corner1">
            <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17" stroke="#94FFDA" stroke-width="2"/>
            </svg>
          </div>

          <div class="corner corner2">
            <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.55184e-08 1H14C15.1046 1 16 1.89543 16 3V17" stroke="#94FFDA" stroke-width="2"/>
            </svg>
          </div>

          <div class="corner corner3">
            <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 16H3C1.89543 16 1 15.1046 1 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
            </svg>
          </div>

          <div class="corner corner4">
            <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.55184e-08 16H14C15.1046 16 16 15.1046 16 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
            </svg>
          </div>

          <div class="games__title">
            <p>Дивовижні<br/> факти</p>
          </div>

          <div class="games__items">
            <div class="scroll">
              <div @click="openGame(quiz.id, quiz.disable)" class="games__item" v-for="(quiz, index) in getQuizData"
                   :class="{disable: quiz.disable}">
                <div v-if="quiz.disable" class="close-icon">
                  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="28" cy="28.8259" r="27.5" fill="#121722" fill-opacity="0.5" stroke="#3CEAAE"/>
                    <path
                      d="M28.0003 13.7083C24.0133 13.7083 20.7912 16.9303 20.7912 20.9173V23.9211H18.3882C17.4002 23.9211 16.5859 24.7354 16.5859 25.7234V40.1415C16.5859 41.1295 17.4002 41.9438 18.3882 41.9438H37.6124C38.6004 41.9438 39.4147 41.1295 39.4147 40.1415V25.7234C39.4147 24.7354 38.6004 23.9211 37.6124 23.9211H35.2094V20.9173C35.2094 16.9303 31.9874 13.7083 28.0003 13.7083ZM28.0003 14.9098C31.3444 14.9098 34.0079 17.5733 34.0079 20.9173V23.9211H21.9927V20.9173C21.9927 17.5733 24.6563 14.9098 28.0003 14.9098ZM18.3882 25.1226H37.6124C37.9456 25.1226 38.2132 25.3902 38.2132 25.7234V40.1415C38.2132 40.4748 37.9456 40.7423 37.6124 40.7423H18.3882C18.055 40.7423 17.7875 40.4748 17.7875 40.1415V25.7234C17.7875 25.3902 18.055 25.1226 18.3882 25.1226ZM28.0003 29.9287C26.9795 29.9287 26.198 30.7101 26.198 31.7309C26.198 32.2707 26.4374 32.7447 26.7988 33.0451V34.7347C26.7988 35.3965 27.3385 35.9362 28.0003 35.9362C28.6621 35.9362 29.2018 35.3965 29.2018 34.7347V33.0451C29.5632 32.7447 29.8026 32.2707 29.8026 31.7309C29.8026 30.7101 29.0211 29.9287 28.0003 29.9287Z"
                      fill="#3CEAAE"/>
                  </svg>

                </div>
                <p :class="{disable: quiz.disable}" class="num" v-html="quiz.id"></p>
                <div class="arrow">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.65099 4.50749C9.26047 4.11697 8.6273 4.11697 8.23678 4.50749C7.84625 4.89802 7.84625 5.53118 8.23678 5.92171L10.1152 7.80008L3.05664 7.80008C2.50436 7.80008 2.05664 8.2478 2.05664 8.80008C2.05664 9.35237 2.50436 9.80008 3.05664 9.80008L10.1152 9.80008L8.23678 11.6785C7.84625 12.069 7.84625 12.7022 8.23678 13.0927C8.6273 13.4832 9.26047 13.4832 9.65099 13.0927L13.2365 9.50719L13.9436 8.80008L13.2365 8.09298L9.65099 4.50749Z"
                          fill="#06243E"/>
                  </svg>
                </div>
                <p :class="{disable: quiz.disable}" class="name" v-html="quiz.name"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="tips-popup-wrapper" v-if="closeItemPopup">
      <div class="tips-popup">
        <div class="tips-popup-corner tips-popup-corner1">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="tips-popup-corner tips-popup-corner2">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.55184e-08 1H14C15.1046 1 16 1.89543 16 3V17" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="tips-popup-corner tips-popup-corner3">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 16H3C1.89543 16 1 15.1046 1 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="tips-popup-corner tips-popup-corner4">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.55184e-08 16H14C15.1046 16 16 15.1046 16 14V4.55184e-08" stroke="#94FFDA" stroke-width="2"/>
          </svg>
        </div>

        <div class="tips-popup__content">
          <div class="tips-popup__text">
            <p>В найближчий час<br>
              факт стане доступним 😉</p>
          </div>
          <div class="tips-popup__btn-wrapper">
            <div @click="closeItemPopup = !closeItemPopup" class="btn">
              <p>закрити</p>
            </div>
          </div>
        </div>
      </div>
    </div>


  </section>
</template>

<style scoped lang="scss">
#bg-audio {
  position: fixed;
  left: -100%;
  top: -100%;
}

.tips-popup-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 9px;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.tips-popup {
  position: relative;
  border: 1px solid rgba(38, 158, 117, 0.20);
  background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
  max-width: calc(668px + 32px);
  width: 100%;

  padding: 8px;
  margin: auto;
  @media screen and (max-width: 600px) {

    margin-left: 8px;
    margin-right: 8px;
    padding: 8px;

  }

  .tips-popup-corner {
    position: absolute;

    svg {
      width: 17px;
      height: auto;
      display: block;
    }

    &.tips-popup-corner1 {
      left: -3px;
      top: -3px;
    }

    &.tips-popup-corner2 {
      right: -3px;
      top: -3px;
    }

    &.tips-popup-corner3 {
      left: -3px;
      bottom: -3px;
    }

    &.tips-popup-corner4 {
      right: -3px;
      bottom: -3px;
    }
  }

  &__content {
    border: 1px solid var(--green-500, #32AC82);

    background: rgba(9, 35, 57, 0.80);
  }

  &__title {
    background: var(--green-300, #3CEAAE);
    padding: 16px 0;

    p {
      color: var(--bg, #06243E);
      text-align: center;
      line-height: 40px !important;
      font-size: 50px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  &__text {
    padding: 32px 60px;
    display: flex;
    justify-content: center;

    p {
      color: var(--White, #FFF);
      text-align: center;

      font-size: 22px;
      font-weight: 400;
      line-height: 140% !important;
    }
  }

  &__btn-wrapper {


    padding: 0 40px 16px 40px;
    display: flex;
    justify-content: center;
    align-items: center;


    .btn {
      @media screen and (min-width: 1023px) {
        &:hover {

          p {
            background: var(--green-300, #94FFDA) !important;
            color: #06243E;
          }
        }
      }
      max-width: 400px;
      width: 100%;
      display: flex;
      justify-content: center;

      &:active {

        p {
          background: var(--green-300, #94FFDA);
          color: #06243E;
        }
      }

      p {
        max-width: 400px;

        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;

        border-radius: 32px;
        border: 1px solid var(--green-300, #3CEAAE);
        background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

        transition: color .2s ease, background .2s ease;
        padding: 21px 16px;
        color: var(--green-300, #3CEAAE);
        text-align: center;

        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        &.color {
          background-color: #3CEAAE;
          color: #06243E;
        }
      }
    }
  }
}

#playButton, #click-audio, #win-audio, #lose-audio {
  position: fixed;
  left: -100%;
  top: -100%;
}

.close-icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  @media screen and (max-width: 600px) {
    padding: 14px;

  }

  svg {
    display: block;
    max-height: 56px;
    width: auto;
    height: 100%;
  }
}

#element {
  @supports (hanging-punctuation: first) and (-webkit-appearance: none) {
    width: 100% !important;
  }
}

.hub {
  background: #06243E;
  padding: 10px;
  max-height: calc((var(--vh2, 1vh) * 100));
  min-height: calc((var(--vh2, 1vh) * 100));
  height: 100vh;
  height: calc((var(--vh2, 1vh) * 100));
  width: 100%;
  position: relative;
  font-family: 'IBM Plex Mono', monospace;
  overflow: hidden;

  ::v-deep sup {
    font-size: 60%;
    line-height: 0;
    vertical-align: top;
  }

  .content {
    background-size: contain;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    //align-items: center;
    justify-content: center;
    padding-top: 70px;

    .corner {
      position: absolute;

      svg {
        width: 17px;
        height: auto;
        display: block;
      }

      &.corner1 {
        left: -3px;
        top: -3px;
      }

      &.corner2 {
        right: -3px;
        top: -3px;
      }

      &.corner3 {
        left: -3px;
        bottom: -3px;
      }

      &.corner4 {
        right: -3px;
        bottom: -3px;
      }
    }

    .img {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .animation2 {
        position: absolute;
        overflow: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .gradient {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(6, 36, 62, 0.00) 78.46%, #06243E 97.04%);
        }
      }

      img {
        width: auto;
        height: 100%;
        //object-fit: cover;
        display: block;

        &.bg-mobile {

        }
      }
    }

    .info {
      position: relative;
      z-index: 2;
      border: 1px solid rgba(38, 158, 117, 0.20);
      background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
      max-width: calc(668px + 32px);
      width: 100%;

      padding: 16px;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 60px;


      &__content {
        border: 1px solid var(--green-500, #32AC82);
        background: rgba(9, 35, 57, 0.60);
      }

      &__title {
        background: var(--green-300, #3CEAAE);
        padding: 16px 0;

        p {
          color: var(--bg, #06243E);
          text-align: center;
          line-height: 40px !important;
          font-size: 50px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }

      &__text {
        padding: 32px 60px;

        p {
          color: var(--White, #FFF);
          text-align: center;

          font-size: 22px;
          font-weight: 400;
          line-height: 140% !important;
        }
      }

      &__btn {

        @media screen and (min-width: 1023px) {
          &:hover {

            p {
              background: var(--green-300, #3CEAAE);
              color: #06243E;
            }
          }
        }

        padding: 0 16px;
        margin: 40px auto 24px auto;
        display: flex;
        justify-content: center;


        &:active {

          p {
            background: var(--green-300, #3CEAAE);
            color: #06243E;
          }
        }

        p {
          max-width: 400px;
          width: 100%;
          display: flex;
          justify-content: center;
          cursor: pointer;

          border-radius: 32px;
          border: 1px solid var(--green-300, #3CEAAE);
          background: var(--grey-t-80, rgba(18, 23, 34, 0.80));

          transition: color .2s ease, background .2s ease;
          padding: 21px 16px;
          color: var(--green-300, #3CEAAE);
          text-align: center;

          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }

    .back_btn {
      position: absolute;
      z-index: 2;
      right: 30px;
      top: 10px;
      cursor: pointer;

      p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 160% !important;
        letter-spacing: -0.32px;
        text-transform: uppercase;
      }
    }

    .games {
      margin-left: 20px;
      margin-right: 20px;
      position: relative;
      z-index: 2;
      border: 2px solid rgba(38, 158, 117, 0.20);
      background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
      max-width: calc(1188px + 32px);
      width: 100%;

      padding: 16px;
      max-height: calc(100% - 70px);
      height: 100%;


      &__content {
        background: rgba(18, 23, 34, 0.80);
        height: 100%;
      }

      &__title {
        padding: 24px 16px;

        p {
          color: var(--green-200, #94FFDA);
          text-align: center;
          font-size: 50px;
          font-weight: 400;
          line-height: 80% !important;
          text-transform: uppercase;

          br {
            display: none;
          }
        }
      }

      &__items {

        border: 1px solid var(--green-500, #32AC82);
        padding: 16px 8px;
        max-height: calc(100% - 88px);
        height: 100%;

        .scroll {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr 1fr;


          grid-gap: 4px;
          overflow-y: auto;
          overflow-x: hidden;
          padding-left: 8px;
          padding-right: 8px;

          height: 100%;
          @media screen and (max-width: 600px) {
            overflow-y: scroll;
          }

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: rgba(241, 241, 241, 0.25);
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--green-500, #32AC82);
            border-radius: 3px;
          }
        }
      }

      &__item {
        user-select: none;
        position: relative;
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
        min-height: 150px;

        background: var(--green-300, #3CEAAE);
        border: 1px solid #3CEAAE;

        transition: background-color .2s ease, border .2s ease;

        &.disable {
          background: var(--grey-t-30, rgba(66, 80, 115, 0.30));
          border-color: rgba(255, 255, 255, 0);
          cursor: pointer;
        }

        &:not(.disable) {

          @media screen and (min-width: 1023px) {
            &:hover {
              border: 1px solid var(--White, #FFF);
              background-color: var(--green-200, #94FFDA);
              cursor: pointer;

              .arrow {
                opacity: 1;
              }
            }
          }

          &:active {
            border: 1px solid var(--White, #FFF);
            background-color: var(--green-200, #94FFDA);

            .arrow {
              opacity: 1;
            }
          }
        }

        .arrow {
          position: absolute;
          right: 16px;
          top: 16px;
          opacity: 0;
          transition: opacity .2s ease;
        }

        .num {
          margin-bottom: 30px;

          color: var(--bg, #06243E);
          font-size: 12px;
          font-weight: 700;
          line-height: 10px !important;

          &.disable {
            color: #3CEAAE;
          }
        }

        .name {
          color: var(--bg, #06243E);
          font-size: 18px;
          font-weight: 700;
          line-height: 110% !important;

          &.disable {
            //display: none;
            color: #3CEAAE;
            filter: blur(4px);
          }
        }
      }
    }

    &.stage1 {
    }

    &.stage2 {
    }

    &.stage3 {
    }
  }

  .sound-btn {
    position: absolute;
    z-index: 20;
    right: 22px;
    top: 8px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 1023px) {

      &:hover {
        cursor: pointer;

        .sound-btn__icon {
          background: var(--green-200, #94FFDA);
        }
      }
    }
    @media screen and (max-width: 600px) {
      top: 2px;
      right: 6px;
    }

    &__text {
      color: #FFF;
      text-align: center;

      font-size: 14px;
      font-weight: 700;
      line-height: 120% !important;
      letter-spacing: -0.28px;
      text-transform: uppercase;
      margin-right: 16px;
    }

    &__icon {
      transition: all .2s ease-in;
      border: 1px solid var(--green-500, #32AC82);
      background: var(--green-300, #3CEAAE);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .hub {
    .content {


      .img {

        img {

        }
      }

      .info {


        .corner {

          svg {

          }

          &.corner1 {

          }

          &.corner2 {

          }

          &.corner3 {

          }

          &.corner4 {

          }
        }

        &__content {

        }

        &__title {

          p {

          }
        }

        &__text {

          p {

          }
        }

        &__btn {

          p {
          }
        }
      }

      .back_btn {
        display: none;

        p {

        }
      }

      .games {

        max-height: calc(100% - 23px);

        .corner {

          svg {

          }

          &.corner1 {

          }

          &.corner2 {

          }

          &.corner3 {

          }

          &.corner4 {

          }
        }

        &__content {

        }

        &__title {
          p {

          }
        }

        &__items {

          .scroll {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: initial;

          }

          &::-webkit-scrollbar {
          }

          &::-webkit-scrollbar-track {
          }

          &::-webkit-scrollbar-thumb {
          }
        }

        &__item {
          min-height: 140px;

          .num {
          }

          .name {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .hub {
    padding: 8px;

    .content {
      padding-top: 56px;

      .img {

        img {

        }
      }

      .info {

        margin-left: 8px;
        margin-right: 8px;
        padding: 8px;

        .corner {

          svg {

          }

          &.corner1 {

          }

          &.corner2 {

          }

          &.corner3 {

          }

          &.corner4 {

          }
        }

        &__content {

        }

        &__title {
          padding: 8px;

          p {
            font-size: 32px;

          }
        }

        &__text {

          padding: 16px;

          p {
            font-size: 15px;

          }
        }

        &__btn {

          margin-top: 24px;
          margin-bottom: 16px;
          max-width: 280px;

          @media screen and (min-width: 1023px) {
            &:hover {

              p {
              }
            }
          }

          &:active {

            p {
            }
          }

          p {
            font-size: 16px;

            padding: 17px 8px;
          }
        }
      }

      .back_btn {
        display: none;

        p {

        }
      }

      .games {

        margin-left: 8px;
        margin-right: 8px;
        padding: 8px;
        max-height: calc(100% - 24px);

        .corner {

          svg {

          }

          &.corner1 {

          }

          &.corner2 {

          }

          &.corner3 {

          }

          &.corner4 {

          }
        }

        &__content {

        }

        &__title {
          p {
            font-size: 32px;
            line-height: 100% !important;

            br {
              display: block;
            }
          }
        }

        &__items {
          padding: 8px;
          max-height: calc(100% - 112px);

          .scroll {


            grid-template-columns: 1fr 1fr;
          }

          &::-webkit-scrollbar {
          }

          &::-webkit-scrollbar-track {
          }

          &::-webkit-scrollbar-thumb {
          }
        }

        &__item {
          padding: 8px;
          min-height: 120px;

          .num {
            margin-bottom: 20px;
          }

          .name {
            font-size: 14px;
          }
        }
      }
    }
  }
  .tips-popup {

    margin-left: 8px;
    margin-right: 8px;
    padding: 8px;

    .corner {

      svg {

      }

      &.corner1 {

      }

      &.corner2 {

      }

      &.corner3 {

      }

      &.corner4 {

      }
    }

    &__content {

    }

    &__title {
      padding: 8px;

      p {
        font-size: 32px;

      }
    }

    &__text {

      padding: 16px;

      p {
        font-size: 15px;

      }
    }

    &__btn-wrapper {
      padding: 0 16px 16px 16px;
    }

    &__btn {
      max-width: 320px;

      @media screen and (min-width: 1023px) {
        &:hover {

          p {
          }
        }
      }

      &:active {

        p {
        }
      }

      p {
        font-size: 16px;
        max-width: 320px;
        padding: 17px 8px;
      }
    }
  }
}

</style>